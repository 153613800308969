import {Controller} from "@hotwired/stimulus"

export default class extends Controller {


    submit(e) {
        e.preventDefault()

        // get email
        const email = this.element.querySelector('#user_email').value
        console.log(email)
        // check if email is filled
        if (email) {
            // check if email is valid
            if (this.validateEmail(email)) {
                // sign in fetch request post as form data
                fetch('/users/sign_in', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    },
                    body: JSON.stringify({
                            user: {
                                email: email
                            }
                        }
                    )
                }
                ).then(response => {
                    response.json().then(data => {
                        if(data.ok) {
                            // redirect to dashboard
                            document.querySelector('#error').innerHTML = 'Bitte Email überprüfen'
                        } else {
                            document.querySelector('#error').innerHTML = 'Ein Fehler ist aufgetreten'
                        }
                    }
                )
                }
                )


            } else {
                document.querySelector('#error').innerHTML = 'Bitte eine gültige Email eingeben'
            }


        }
    }

    validateEmail(email) {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
    }
}
