import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    ShowForm(e) {
        e.stopPropagation()
        e.preventDefault()
        const id = e.currentTarget.dataset.id
        const form = document.getElementById(`form_${id}`)
        form.classList.toggle('hidden')
        document.querySelector('.mainblock').scrollBy({top:300})


    }

    Register(e) {
        e.preventDefault()
        e.stopPropagation()

        const id = e.currentTarget.dataset.id
        const form = document.getElementById(`form_${id}`)
        form.querySelector('.error').innerHTML = ''
        const first_name = form.querySelector('.first_name').value
        const last_name = form.querySelector('.last_name').value
        const email = form.querySelector('.email').value

        // check if all fields are filled
        if (first_name && last_name && email) {
            // request post register
            fetch('/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    },
                    body: JSON.stringify(
                        {
                            id: id,
                            attendee: {
                                first_name: first_name,
                                last_name: last_name,
                                email: email
                            },

                        })
                }
            ).then(response => response.text()).then(html => Turbo.renderStreamMessage(html));


        } else {
            form.querySelector('.error').innerHTML = 'Bitte alle Felder ausfüllen'
        }

    }
    showList(e) {
        e.preventDefault()
        e.stopPropagation()
        const id = e.currentTarget.dataset.id
        const list = document.getElementById(`attendees_${id}`)
        list.classList.remove('hidden')
    }
    hideList(e) {
        e.preventDefault()
        e.stopPropagation()
        const id = e.currentTarget.dataset.id
        const list = document.getElementById(`attendees_${id}`)
        list.classList.add('hidden')
    }
}
